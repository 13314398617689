.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    background-color: #333;
    border-radius: 8px;
    text-align: center;
  }
  
  h2 {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 20px;
  }

  .contact-block {
    font-size: 1.2rem;
    text-align: left;
    color: #fff;
    flex: 1; /* Take up the available space on the left */
    padding-right: 50px; /* Space between text and form */
  }
  
  .contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  label {
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  textarea {
    resize: none;
  }
  
  input:focus, textarea:focus {
    outline: none;
    border-color: #da2c2c;
  }
  
  .submit-button {
    padding: 12px 20px;
    background-color: #da2c2c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #700000;
  }

  .email-message{
    color: #fff; 
  }
  
  /* Adjust spacing for small screens */
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column-reverse;
      padding: 20px;
      align-items: center;
    }

    .contact-form {
      width: 80%;
    }

    .contact-block{
      padding: 0 0.5em;
      margin-top: -1.5em;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      font-size: 1.3rem;
    }

    label {
      font-size: 1.3rem;
    }

    input {
      font-size: 1.3rem
    }
  }