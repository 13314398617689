.footer {
    background-color: #fff; /* White background */
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    color: #333; /* Text color */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow */
  }
  
  .footer-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-logo img {
    max-height: 800px;
    height: 200px;
    width: auto;
  }
  
  .footer-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-menu ul li {
    margin-bottom: 10px;
  }
  
  .footer-menu ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  .footer-hours, .footer-contact {
    max-width: 250px;
  }
  
  .footer-content h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #000;
  }
  
  .footer p {
    margin: 5px 0;
  }

  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-menu ul {
      text-align: center;
      font-size: 1rem;
    }

    .footer-content h3 {
      font-size: 1.6rem;
    }

    .footer-content p {
      font-size: 1.2rem;
    }
  
    .footer-hours, .footer-contact {
      text-align: center;
    }
  }