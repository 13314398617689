button{
    min-height: 20px;
    min-width: auto;
    padding: 1% 1.5%;
    background-color: #da2c2c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.center-button{
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the button over the image */
    z-index: 20;
    cursor: pointer;
    white-space: nowrap; /* Prevents text from wrapping */
    /*overflow: hidden; /* Ensures text is contained */
    text-overflow: ellipsis; /* Adds an ellipsis if text overflows */
    opacity: 1;
    padding: 0.5rem;
}

button:hover {
    background-color: #900000;
    color: #fff;
}

/* Logo positioning - fixed on top of slideshow */
.logo {
    max-width: 100%; /* Keep responsive */
    height: auto;
    display: flex;
    border-radius: 8px; /* Optional */
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the image */
}
  
.logo img {
    border-radius: 30%;
    opacity: 0.7;
    position: relative;
    max-width: 400px;
    min-width: 300px;
    width: 10%;
    height: auto;
}

.social-media-links {
    padding-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between icons */
  }
  
  .social-icon {
    color: #333; /* Default color */
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #da2c2c; /* Change color on hover (customize per brand color) */
  }
  
  a {
    text-decoration: none;
    color: #fff;
  }

  @media (max-width: 768px) {
    a {
        margin: 0.5rem;
        font-size: 1.4rem;
    }
}
