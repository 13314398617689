.Header-logo img{
    max-height: 800px;
    height: 1000px;
    width: 100%;
}

.header-container{
    position: relative;
    max-width: 100vw; /* Set maximum width for responsiveness */
    max-height: 90vh; /* Optional for vertical responsiveness */
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    
    .header-container{
        max-width: 90vw;
    }
}