  .whatsapp-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    background-color: #25D366; /* WhatsApp green */
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
  }

  .whatsapp-button img {
    width: 30px;
    height: 30px;
  }
  
  .whatsapp-button:hover {
    background-color: #1ebe57; /* Darker green on hover */
  }