/* Container for the entire Google Reviews section */
.reviews-container {
  padding: 30px;
  background-color: #333;
  display: flex;
  justify-content: center; /* Align items horizontally in the center */
  align-items: center;     /* Align items vertically (optional, if needed) */
  flex-direction: column;  /* Stack reviews vertically */}

/* Title for the reviews section */
.reviews-title {
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
}

.rating-summary {
  display: flex;
  align-items: center;
}

.total-reviews {
  margin-right: 1rem;
  font-size: 1.8rem;
  color: #fff;
}

/* Individual review card container in horizontal layout */
.review-card {
  flex: 0 0 auto; /* Prevent shrinking, take width based on content */
  max-width: 1200px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px; /* Spacing between cards */
  padding: 15px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.review-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Review card header */
.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.reviewer-identity{
  display: flex;
}

.reviewer-photo {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
}

/* Reviewer name styling */
.reviewer-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
}

/* Review rating stars */
.review-rating {
  color: #ffa500;
  font-size: 1.8rem;
}

/* Review text */
.review-text {
  font-size: 1em;
  line-height: 1.4;
  color: #555;
  padding: 0px 30px;
  margin-top: 10px;
}

/* Date of the review */
.review-date {
  font-size: 0.75rem;
  color: #999;
  margin-top: 10px;
}

.see-more-btn {
  background: none;
  color: #da2c2c;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  padding: 0;
}

.see-more-btn:hover {
  color: #da2c2c;
}

.google-reviews-container {
  display: flex;
  justify-content: center; /* Aligns the link to the right */
  padding: 20px; /* Optional padding */
}

.google-reviews-link{
  font-size: 1.5rem;
  text-decoration: underline;
  color: #fff;
}

.google-reviews-container :hover{
  background-color: #700000;
}

@media (max-width: 768px) {
  .reviewer-name{
    font-size: 1.4rem;
  }

  .review-card:first-child {
    margin-top: 0;
  }
  
  .review-text {
    font-size: 1.2rem;
    padding: 0;
  }

  .review-rating {
    font-size: 1.6rem;
  }

  .review-date {
    font-size: 1rem;
  } 
  
  .google-reviews-container{
    padding: 0 0 1rem 0;
  }
}
