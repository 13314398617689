  .middle-section {
    position: relative;
    margin-top: -2rem;
  }

  .section-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 3rem 1.5rem 0 1.5rem;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .section-image {
    width: 45%;
    max-width: 600px;
    border-radius: 30px;
    margin-right: 20px;
  }
  
  .section-text {
    width: 45%;
    max-width: 600px;
    text-align: left;
  }
  
  .section-text p {
    color: #fff;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  
  /* For mobile responsiveness */
  @media (max-width: 768px) {
    .content-container {
      margin: 1em;
      flex-direction: column;
    }

    .section-title{
      font-size: 2rem;
    }
  
    .section-image, .section-text {
      width: 100%;
      max-width: 100%;
      margin-right: 1em;
      text-align: justify;
    }

    .section-text p{
      font-size: 1.2rem
    }
  }