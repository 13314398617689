.services-container {
    padding: 40px;
    background-color: #333;
    text-align: center;
  }
  
  .services-heading {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #fff;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Service card styling */
  .service-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .service-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .service-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .service-description {
    font-size: 1rem;
    color: #666;
  }

  @media (max-width: 1080px) {
    .services-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Mobile: 1 column (1x6 layout) */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: repeat(1, 1fr);
    }

    .service-title {
      margin-top: 1em;
    }

    .service-description {
      font-weight: 450;
      font-size: 1.2rem;
    }
  }