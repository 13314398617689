/* Slideshow wrapper to contain both logo and slideshow */
  .slideshow-wrapper {
    visibility: visible;
    position: relative;
    width: 100%;
    height: auto; /* Fixed height for the slideshow */
    /*overflow: hidden; /* Hide overflow for the sliding effect */
  }

  .phone-slideshow-wrapper{
    display: none;
    visibility: hidden;
  }
  
  /* Slideshow container - full width and fixed height */
  .slideshow-container {
    width: 100%;
    height: auto;
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  /* Each individual slide */
  .slide {
    min-width: 100%; /* Take up 100% of the width */
    height: auto; /* Fixed height */
    transition: opacity 1s ease-in-out; /* Smooth fade between slides */
    opacity: 0;
    position: absolute; /* Layering slides on top of each other */
  }
  
  .slide.active {
    opacity: 1;
    position: relative;
    transition: opacity 1s ease-in-out;
  }
  
  /* Ensure images are full width and height */
  .slide img {
    width: 100vw;
    height: 100vh;
    object-fit: cover; /* Ensure images maintain aspect ratio and cover the area */
  }

  @media (max-width: 768px){
    .slideshow-wrapper {
      width: 100%;
      max-width: 600px;
      margin: auto;
      padding: 10px;
      /*overflow: hidden; */
    }

    .slide{
      width: 100vw;
    }
  
    .slide img {
      width: 100%;
      height: 1000px; /* Automatically adjust for smaller screens */
      max-height: 90vh; /* Further height limit on mobile */
      object-fit: cover;
      object-position: center;
      border-radius: 20px; /* Rounded edges for individual images */
    }
  }